import { combineReducers } from '@reduxjs/toolkit';
import userDataReducer from './slices/userDataSlice';
import tenantDataReducer from './slices/tenantDataSlice';

const rootReducer = combineReducers({
  userData: userDataReducer,
  tenantData: tenantDataReducer,
  // スライスをここに追加
});

export default rootReducer;
