import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

interface Tenant {
  id: string;
  data: {
    tenant_name: string;
    auth0_namespace: string;
    user_limit: number;
    created_at: any; // TODO: 型を指定
    updated_at: any; // TODO: 型を指定
  };
}

interface TenantState {
  tenantData: Tenant[];
  loading: boolean;
  error: string | null;
}

const initialState: TenantState = {
  tenantData: [],
  loading: false,
  error: null,
};

export const fetchTenantData = createAsyncThunk(
  'tenantData/fetchTenantData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/api/fetchTenants');
      return response.data; // TODO: APIレスポンスの形式はTenant[]?
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue('テナントデータの取得に失敗しました。');
    }
  },
);

export const createTenantData = createAsyncThunk(
  'tenantData/createTenantData',
  async (data: { tenant_name: string; auth0_namespace: string; user_limit: number; }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post('/api/createTenant', data);
      // 成功時にfetchTenantDataを実行してデータを更新
      dispatch(fetchTenantData());
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        if (axiosError.response.status === 400) {
          return rejectWithValue({ errorMessage: '事業者名またはドメインが重複しています' });
        } if (axiosError.response.status === 422) {
          const errorResponse = axiosError.response.data.error;
          const newErrors = {
            tenantError: errorResponse.tenError,
            namespaceError: errorResponse.nameError,
            userLimitError: errorResponse.limitError,
          };
          return rejectWithValue(newErrors);
        }
        return rejectWithValue({ errorMessage: JSON.stringify(axiosError.response.data?.error) || '予期しないエラーが発生しました' });
      }
      return rejectWithValue({ errorMessage: 'データを追加できませんでした' });
    }
  },
);

// テナント更新
export const updateTenantData = createAsyncThunk(
  'tenantData/updateTenantData',
  async (updateData: { id: string; tenant_name: string; auth0_namespace: string; user_limit: number; }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post('/api/updateTenant', updateData);
      // 成功時にfetchTenantDataを実行してデータを更新
      dispatch(fetchTenantData());
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        if (axiosError.response.status === 422) {
          return rejectWithValue(axiosError.response.data);
        }
        return rejectWithValue({ errorMessage: JSON.stringify(axiosError.response.data?.error) || '予期しないエラーが発生しました' });
      }
      return rejectWithValue({ errorMessage: 'データの更新に失敗しました' });
    }
  },
);

// テナント削除
export const deleteTenantData = createAsyncThunk(
  'tenantData/deleteTenantData',
  async (ids: string[], { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post('/api/deleteTenants', { ids });
      // 成功時にfetchTenantDataを実行してデータを更新
      dispatch(fetchTenantData());
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        return rejectWithValue({ errorMessage: JSON.stringify(axiosError.response.data?.error) || '予期しないエラーが発生しました' });
      }
      return rejectWithValue({ errorMessage: 'データの削除に失敗しました' });
    }
  },
);

const tenantDataSlice = createSlice({
  name: 'tenantData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTenantData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTenantData.fulfilled, (state, action) => {
        state.loading = false;
        state.tenantData = action.payload;
        state.error = null;
      })
      .addCase(fetchTenantData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
        state.tenantData = [];
      })
      .addCase(createTenantData.pending, (state) => {
        state.loading = true;
      })
      .addCase(createTenantData.fulfilled, (state, action) => {
        state.loading = false;
        // state.tenantData = action.payload; // dispatch(fetchTenantData())があれば不要
        state.error = null;
      })
      .addCase(createTenantData.rejected, (state, action) => {
        state.loading = false;
        // action.payloadにはrejectWithValueで返された値が設定される
        state.error = action.payload as any;
      })
      // updateTenantDataの処理を追加
      .addCase(updateTenantData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTenantData.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateTenantData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as any; // APIから受け取ったエラーメッセージを使う
      });
  },
});

export default tenantDataSlice.reducer;
