import React, { useState, Suspense, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Provider } from 'react-redux';
import store from './store';

const Header = React.lazy(() => import('./components/Header/Header'));
const SideMenu = React.lazy(() => import('./components/SideMenu/SideMenu'));
const Home = React.lazy(() => import('./pages/home/Home'));
const ShowTenants = React.lazy(() => import('./pages/show-tenants/ShowTenants'));
const CreateTenants = React.lazy(() => import('./pages/create-tenant/CreateTenant'));
const UpdateTenants = React.lazy(() => import('./pages/update-tenant/UpdateTenant'));
const UserData = React.lazy(() => import('./pages/show-users/ShowUsers'));
const CreateUser = React.lazy(() => import('./pages/create-user/CreateUser'));
const UpdateUser = React.lazy(() => import('./pages/update-user/UpdateUser'));

function App() {
  const [isOpen, setIsOpen] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const { isLoading, isAuthenticated, loginWithRedirect, user } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserRoles = async (userId: string) => {
      try {
        const response = await fetch(`/api/users/${userId}/roles`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        type Role = { name: string };
        const roles: Role[] = await response.json();
        const isAdminRole = roles.some((role: Role) => role.name === 'Admin');
        setIsAdmin(isAdminRole);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        // Todo: 適切なエラーハンドリングを行う
      }
    };
    if (isLoading) {
      // 認証がローディング中の場合は何もしない。
      return;
    }
    if (!isAuthenticated) {
      // ユーザーが未認証の場合はログインフローを実行する。
      loginWithRedirect();
    } else if (user) {
      // ユーザーが認証され、user情報がある場合、/homeにリダイレクトし、ロールをフェッチする。
      navigate('/home');
      if (user?.sub) {
        fetchUserRoles(user.sub);
      }
    }
  }, [isLoading, loginWithRedirect]);

  if (isLoading || !isAuthenticated) {
    return <div>Loading...</div>; // 初期ロード時に何も表示させない
  }

  return (
    <Provider store={store}>
      <div className="App">
        {/* SuspenseでHeaderとSideMenuをラップ */}
        <Suspense fallback={<div>Loading...</div>}>
          <Header isOpen={isOpen} setIsOpen={setIsOpen} />
          <SideMenu />
        </Suspense>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            {/* ロールなしユーザー */}
            <Route path="/" element={<Home />} />
            {/* Adminロールのユーザー */}
            {isAdmin && <Route path="/showtenants" element={<ShowTenants />} />}
            {isAdmin && <Route path="/userdata" element={<UserData />} />}
            {isAdmin && <Route path="/createtenant" element={<CreateTenants />} />}
            {isAdmin && <Route path="/updatetenant" element={<UpdateTenants />} />}
            {isAdmin && <Route path="/createuser" element={<CreateUser />} />}
            {isAdmin && <Route path="/updateuser" element={<UpdateUser />} />}
            {/* 認証されていない場合や、確認中の場合にはログイン画面にリダイレクト */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Suspense>
      </div>
    </Provider>
  );
}

export default App;
