import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

// UserData型定義
//
// Auth0から1回のリクエストで取得できるデータは、
// 全体の件数（total）と1ページ分（最大100件）のデータ。
// そのため、データについてはサーバー側で複数回リクエストを投げて、
// それらの結果をマージした結果をusersに設定する。
interface UserData {
  total: number,
  users: User[],
}

// User型定義
export interface User {
  user_id?: string;
  name?: string;
  email: string;
  email_verified?: boolean;
  last_login?: string;
  logins_count?: number;
  nickname?: string;
  given_name: string;
  family_name: string;
  user_metadata?: {
    namespace: string;
  };
}

// Stateの型定義
interface UserDataState {
  userData: UserData;
  loading: boolean;
  error: { errorMessage: string; errorDetails?: any } | null;
}

// 初期状態
const initialState: UserDataState = {
  userData: { total: 0, users: [] },
  loading: false,
  error: null,
};

// 共通のエラー処理
const handleAxiosError = (error: any) => {
  const axiosError = error as AxiosError<any>;
  let errorMessage = '予期しないエラーが発生しました';
  if (axiosError.response) {
    errorMessage = axiosError.response.data?.error || errorMessage;
    if (axiosError.response.status === 422) {
      console.log(axiosError.response.data.errors || {});
    }
  }
  return { errorMessage };
};

// ユーザー一覧を非同期に取得するアクション
export const fetchUserDataAction = createAsyncThunk(
  'users/fetchUserData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/api/userdata');
      return response.data;
    } catch (error: any) {
      return rejectWithValue(handleAxiosError(error));
    }
  },
);

// 新しいユーザーを追加する非同期アクション
export const createUserAction = createAsyncThunk(
  'users/createUser',
  async (userFormData: User, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post('/api/createUser', userFormData);
      dispatch(fetchUserDataAction());
      return response.data;
    } catch (error: any) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 409) {
        return rejectWithValue({ errorMessage: '既にユーザが登録されています。' });
      }
      return rejectWithValue(handleAxiosError(error));
    }
  },
);

// ユーザー更新の非同期アクション
export const updateUserAction = createAsyncThunk(
  'users/updateUser',
  async (userData: User, { dispatch, rejectWithValue }) => {
    try {
      // TODO: 更新はPostではなくPutですべき
      const response = await axios.post('/api/updateUser', userData);
      // APIリクエスト成功後、自動的にユーザーデータを再フェッチ
      dispatch(fetchUserDataAction());
      return response.data;
    } catch (error) {
      const axiosError = (error as AxiosError);
      if (axiosError && axiosError.response) {
        const errorMessage: string = axiosError.response.data?.error || '更新に失敗しました。';
        const errorDetails: any = axiosError.response.data?.details || {};
        return rejectWithValue({
          errorMessage,
          errorDetails,
        });
      }
      return rejectWithValue({
        errorMessage: '更新処理中に未知のエラーが発生しました。',
        errorDetails: {},
      });
    }
  },
);

// ユーザー削除
export const deleteUsersAction = createAsyncThunk(
  'users/deleteUsersAction',
  async (ids: string[], { dispatch, rejectWithValue }) => {
    try {
      await axios.post('/api/deleteUsers', { ids });
      // 削除をawaitしているにも関わらず、
      // 少し待ってからユーザーデータを取得しないと
      // 最新データが取得されないため、2秒の待ちを追加。
      //
      // createUserAction、updateUserActionの場合、
      // 登録前にユーザー数を確認するためにfetchUserDataActionを行っていることが影響しているようで、
      // 登録後のfetchUserDataActionでは、正常に最新データを取得できている。
      // （登録前のユーザー数確認をコメントアウトすると、最新データを取得できなくなる。）
      // なぜか分からないが、2回fetchUserDataActionすることで、
      // 2回目のfetchUserDataActionが少し待ちの状態になってから実行されているのか？
      await new Promise((resolve) => { setTimeout(resolve, 2000); });
      dispatch(fetchUserDataAction());
      return undefined;
    } catch (error: any) {
      return rejectWithValue(handleAxiosError(error));
    }
  },
);

const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetchUserDataActionがpendingの場合の処理。ローディング状態をtrueに設定。
    builder.addCase(fetchUserDataAction.pending, (state) => {
      state.loading = true;
    });
    // fetchUserDataActionが成功した場合の処理。
    builder.addCase(fetchUserDataAction.fulfilled, (state, action) => {
      state.loading = false;
      state.userData = action.payload;
    });
    // fetchUserDataActionがrejectedされた場合の処理。
    builder.addCase(fetchUserDataAction.rejected, (state, action) => {
      state.loading = false;
      // action.payloadがオブジェクトであり、かつerrorMessageプロパティがstring型である場合に、
      // このエラーオブジェクトをstate.errorに割り当てる
      if (
        typeof action.payload === 'object'
        && action.payload !== null
        && 'errorMessage' in action.payload
        && typeof action.payload.errorMessage === 'string'
      ) {
        state.error = action.payload as { errorMessage: string; errorDetails?: any }; // 明示的な型アサーション
      } else {
        // action.payloadが期待した形式でない場合は、デフォルトエラーメッセージをセット
        state.error = { errorMessage: '予期しないエラーが発生しました' };
      }
    });
  },
});

export default userDataSlice.reducer;
